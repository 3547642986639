import { createRouter, createWebHistory } from 'vue-router'
import store from '@/api/store.js'

const routes = [
  {
    path: '/',
    component: () => import('../views/Container.vue'),
    children: [
      {
        name: 'Home',
        path: '',
        component: () => import('../views/Home.vue'),
        meta: {
          title: '',
          keepAlive: true
        }
      },
      {
        name: 'User',
        path: 'user',
        component: () => import('../views/User.vue'),
        meta: {
          title: '使用者設定',
          keepAlive: true
        }
      },
      {
        name: 'Issue',
        path: 'issue',
        redirect: { name: 'Home' }
      },
      {
        path: 'issue/:code',
        component: () => import('../views/Issue.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        name: 'Post',
        path: 'post',
        component: () => import('../views/Post.vue'),
        meta: {
          title: '新增主題',
          keepAlive: false
        }
      },
      {
        name: 'Modify',
        path: 'post/:code',
        component: () => import('../views/Post.vue'),
        meta: {
          title: '編輯主題',
          keepAlive: false
        }
      }
    ]
  },
  {
    name: 'Login',
    path: '/login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: '',
      keepAlive: true
    }
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('../views/Warning.vue'),
    meta: {
      title: '錯誤',
      keepAlive: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASEFOLDER),
  linkActiveClass: 'active',
  routes
})

// 路由導航守衛
router.beforeEach((to, from, next) => {
  const token = store.getters['auth/isAuth']
  if (to.name !== 'Login' && !token) {
    next({ name: 'Login' })
  } else if (to.name === 'Login' && token) {
    next({ name: 'Home' })
  } else {
    next()
  }
})

export default router
