export default function(err) {
  let errText = ''
  switch (err.status) {
    case 400:
      if (err.data.hasOwnProperty('errors')) {
        Object.keys(err.data.errors).forEach(key => {
          errText += err.data.errors[key].join('<br />')
          errText += '<br />'
        })
        break
      }
      errText = err.data.title
      break
    case 401:
      errText = '登入資訊失效，請重新登入'
      break
    default:
      errText = '連線伺服器發生錯誤'
      break
  }
  return errText
}
