import router from '../router'
import AxiosErr from './AxiosErr.js'
import Swal from 'sweetalert2/dist/sweetalert2.js'

const ErrorAlert = (msg) => {
  Swal.fire({
    title: 'Oops! 發生錯誤',
    html: msg,
    icon: 'error'
  })
}
const SuccessAlert = (msg) => {
  Swal.fire({
    title: msg,
    icon: 'success',
    showConfirmButton: false,
    timer: 1000,
  })
}

export const PostAlert = () => {
  SuccessAlert('新增成功')
}

export const ReplyAlert = () => {
  SuccessAlert('回覆成功')
}

export const PutAlert = () => {
  SuccessAlert('修改完成')
}

export const LogoutAlert = () => {
  SuccessAlert('登出成功')
  router.push({ name: 'Login' })
}

export const AxiosAlert = (err) => {
  let errText = AxiosErr(err)
  ErrorAlert(errText)
}

export const OopsAlert = (msg, routerName) => {
  ErrorAlert(msg)
  if (routerName) router.push({ name: routerName })
}

export const ModifyAlert = (target, isModify) => {
  if (!isModify) {
    router.push({ name: target })
    return
  }
  Swal.fire({
    title: '返回?',
    text: '您剛剛有異動欄位內容，請問是否離開且不儲存?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#DD6B55',
    confirmButtonText: '確認離開',
    cancelButtonText: '取消',
  }).then(res => {
    if (res.isConfirmed) {
      router.push({ name: target })
    }
  })
}

export const DeleteAlert = () => {
  return Swal.fire({
    title: '是否確認刪除?',
    text: '請注意，您將無法恢復刪除後的資料',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#DD6B55',
    confirmButtonText: '確認刪除',
    cancelButtonText: '取消',
  })
}

let MsgAlert = {}
MsgAlert.install = function (app) {
  app.config.globalProperties.$alert = {
    OopsAlert,
    PostAlert,
    LogoutAlert,
    ReplyAlert,
    PutAlert,
    ModifyAlert,
    DeleteAlert,
    AxiosAlert
  }
}
export default MsgAlert
