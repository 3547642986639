export default {
  namespaced: true,
  state: {
    jwt: null,
    refreshToken: null,
    email: null,
    name: null,
    picture: null
  },
  getters: {
    isAuth: state => {
      return Boolean(state.jwt)
    },
    getJWT: state => {
      return state.jwt
    },
    getRefresh: state => {
      return state.refreshToken
    }
  },
  mutations: {
    SET_AUTH(state, data) {
      state.jwt = data.jwt
      state.refreshToken = data.refreshToken
      state.email = data.email
      state.name = data.name
      state.picture = data.picture
    },
    DELETE_AUTH(state) {
      state.jwt = null
      state.refreshToken = null
      state.email = null
      state.name = null
      state.picture = null
    }
  },
  actions: {
    setAuth(context, options) {
      context.commit('SET_AUTH', options)
    },
    deleteAuth(context) {
      context.commit('DELETE_AUTH')
    }
  }
}
