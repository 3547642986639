import { createStore } from 'vuex'
import SecureLS from 'secure-ls'
import createPersistedState from 'vuex-persistedstate'
import auth from './auth.js'

var ls = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionSecret: process.env.VUE_APP_KEY
});

const store = createStore({
  strict: true,
  modules: {
    auth
  },
  plugins: [
    createPersistedState({
      key: process.env.VUE_APP_KEY,
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ]
})

export default store
