import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './api/store.js'
import MsgAlert from './helper/MsgAlert.js'

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  if (to.meta.title) {
    document.title = to.meta.title + ' - ' + process.env.VUE_APP_TITLE
  } else document.title = process.env.VUE_APP_TITLE
  next()
})

// fontawesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import {
  faComments,
  faEdit,
  faPaperPlane,
  faPlus,
  faReply,
  faTrashAlt,
  faUser
} from '@fortawesome/free-solid-svg-icons'
library.add(faFacebookSquare)
library.add(faComments)
library.add(faEdit)
library.add(faPaperPlane)
library.add(faPlus)
library.add(faReply)
library.add(faTrashAlt)
library.add(faUser)

// sweetalert
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

// loading-overlay
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// vue-easy-lightbox
import VueEasyLightbox from 'vue-easy-lightbox'

import gAuthPlugin from 'vue3-google-oauth2'
const gAuthOptions = {
  clientId: '306134776622-lmqev7h5as131qjmcfi032g1c5cb5oph.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'consent',
  fetch_basic_profile: true
}

createApp(App)
  .use(router)
  .use(store)
  .use(VueSweetalert2)
  .use(VueLoading, { loader: 'dots', color: '#87CEFA' })
  .use(VueEasyLightbox)
  .use(gAuthPlugin, gAuthOptions)
  .use(MsgAlert)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')
