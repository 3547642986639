<template>
  <router-view />
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/theme.css'

export default {}
</script>
